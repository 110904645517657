.App {
  text-align: center;
  height: 100vh;
}

.line {
  width: auto;
  text-align: center;
  line-height: 30px !important;
  font-size: 40px !important;
  font-weight: 600;
  margin: 0;
}

.main_title {
  font-size: 40px;
  font-weight: 700;
  color: #060828;
  width: 100%;
  line-height: 30px;
  display: flex;
  justify-content: center !important;
}

.main_title .line::after {
  content: "";
  display: block;
  width: 100px;
  height: 2px;
  background: #060828;
  margin: 20px 0 10px calc(50% - 50px);
}

.main_title span {
  color: #005761;
}

.sub-heading {
  text-align: center;
  font-weight: 500;
  color: #060828;
}

#root {
  overflow-x: hidden;
}


@media only screen and (max-width: 1024px) {}



@media (min-width: 992px) and (max-width: 1199px) {
  .line {
    line-height: 10px !important;
    font-size: 32px !important;
  }

  @media only screen and (max-width: 768px) {
    .line {
      font-size: 30px !important;
      line-height: 20px !important;
    }

    .sub-heading {
      font-size: 18px;
    }
  }
}


@media (max-width: 528px) {
  .line {
    font-size: 23px !important;
    line-height: 0px !important;
    letter-spacing: 1px;
  }

  .sub-heading {
    font-size: 18px;
    margin: 10px 0 20px 0;
  }
}