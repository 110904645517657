.header-main-container {
  display: flex;
  min-height: 5rem;
  gap: 2rem;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  position: relative;
}

.header-middle-section {
  display: flex;
  align-items: center;
  gap: 3rem;
}

.header-main-container-link {
  text-decoration: none;
  color: black;
  letter-spacing: 2px;
  font-size: 1rem;
}

.header-middle-section-container {
  display: flex;
  gap: 2rem;
}

.header-middle-section-container-options {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  position: relative;
  height: 5rem;
}

.header-middle-section-container-options:hover + div {
  display: block;
}

.header-middle-section-container-company-options {
  position: absolute;
  top: 5rem;
  background: white;
  border-bottom: 5px solid #005761;
  border-radius: 10px;
  padding: 15px 0;
  min-width: 13.2rem;
  display: none;
}

.header-middle-section-container-company-options ul {
  list-style-type: none;
  font-size: 14px;
  font-size: 14px;
  padding-left: 1rem;
  color: #060828;
  transition: 0.5s;
  text-decoration: none;
}

.header-middle-section-container-company-options li {
  margin: 1rem;
  text-decoration: none;
}

.header-middle-section-container-company-options:hover {
  display: block;
}

.header-middle-section-container-company-options li:hover {
  transition: 0.5s;
  color: #005761;
  border-top: 1px solid #005761;
  padding: 3px 0;
  border-bottom: 1px solid #005761;
}

.hamburger-menu {
  display: none;
}

.hamburger-menu div {
  width: 30px;
  height: 4px;
  border-radius: 4px;
  background-color: black;
  margin: 9px;
}

@media only screen and (max-width: 1200px) {
  .header-main-container {
    gap: 0.8rem;
    justify-content: space-around;
  }

  .header-middle-section-container {
    gap: 1rem;
  }

  .header-middle-section {
    gap: 1rem;
  }
}

@media only screen and (max-width: 1080px) {
  .hamburger-menu {
    display: block;
  }

  .my-class {
    position: absolute;
    top: 80px;
    text-align: center;
    background-color: #e7e7e7;
    width: 100vw;
    height: 100vh;
  }

  #header-services-options {
    top: 7.2rem;
  }

  #header-hire-us-options {
    top: 12.2rem;
  }

  .header-middle-section-container-company-options {
    width: 100vw;
    z-index: 100;
    top: 3.2rem;
  }

  #header-blog-options,
  #header-portfolio-options {
    display: block;
    margin-bottom: 2rem;
  }

  .header-middle-section-container-options {
    justify-content: center;
  }

  .header-right-section,
  .header-middle-section {
    display: none;
  }

  /*
    position: absolute;
    background-color: rgb(246, 194, 37);
    width: 100%;
    top: 40px;
  }*/

  .header-middle-section-container {
    display: block;
    width: 100%;
    top: 10px;
  }

  .header-middle-section-container span {
    display: block;
    width: 100%;
    top: 10px;
  }
}

@media (min-width: 665px) and (max-width: 920px) {
}

@media only screen and (max-width: 420px) {
  .header-left-section img {
    width: 250px;
    height: 35px;
  }

  .hamburger-menu div {
    width: 25px;
    height: 4px;
    margin: 6px;
  }

  .header-main-container {
    min-height: 4rem;
  }

  .my-class {
    top: 62px;
  }
}

@media only screen and (max-width: 320px) {
  .header-left-section img {
    width: 10rem;
  }

  .hamburger-menu div {
    width: 20px;
    height: 2px;
    margin: 4px;
  }
}
