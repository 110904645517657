.ourvaluable_client_sec {
  background-color: #f6f6f6;
  padding: 80px 0;
}

.ourvaluable_client_container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.client_slider {
  display: flex;
  overflow: hidden;
  position: relative;
  width: 960px;
  gap: 2rem;
  margin: 0 auto;
}

.client_slider::before {
  content: "";
  left: 0;
  top: 0;
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}

.client-slider-track {
  animation: scroll 10s linear infinite;
  display: flex;
  width: calc(350px * 14);
}

.client_slider::after {
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.client_logo {
  min-width: 250px;
  border: 1px solid #ddd;
  border-radius: 4rem;
  padding: 0.6rem;
  margin: 0 20px;
  align-items: center;
  display: flex;
  justify-content: center;
  background: #fff;
}

.client_logo img {
  object-fit: cover;
}

.button-animate {
  display: block;
  text-align: center;
  margin-top: 20px;
}

.client-btn {
  min-width: 100px;
  height: 50px;
  padding: 0 30px;
  border-radius: 25px;
  background: #005761;
  border: 1px solid #005761;
  outline: 0;
  font-size: 18px;
  color: #fff;
  font-weight: 700;
  position: relative;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  animation: jittery 2s infinite;
}

@keyframes jittery {

  5%,
  50% {
    transform: scale(1);
  }

  10% {
    transform: scale(0.9);
  }

  15% {
    transform: scale(1.15);
  }

  20% {
    transform: scale(1.15) rotate(-10deg);
  }

  25% {
    transform: scale(1.15) rotate(15deg);
  }

  30% {
    transform: scale(1.15) rotate(-3deg);
  }

  35% {
    transform: scale(1.15) rotate(10deg);
  }

  40% {
    transform: scale(1.15) rotate(0);
  }
}

.client-btn:hover {
  transition: 0.5s;
  background: #000;
  border: 1px solid #000;
  color: #fff;
  animation: heartbeat 0.2s infinite;
}

@keyframes heartbeat {
  50% {
    transform: scale(1.05);
  }
}

/* // Animation */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-250px * 7));
  }
}



@media (min-width: 1200px) {
  .ourvaluable_client_container {
    width: 100%;
    max-width: 1140px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .ourvaluable_client_container {
    max-width: 960px;
  }

  .ourvaluable_client_sec {
    padding: 50px 0;
  }
}

@media only screen and (max-width: 768px) {
  .ourvaluable_client_container {
    max-width: 720px;
  }

  .ourvaluable_client_sec {
    padding: 40px 0;
  }
}

@media (max-width: 528px) {
  .ourvaluable_client_container {
    max-width: fit-content;
  }

  .ourvaluable_client_sec {
    padding: 28px 0;
  }

  .client_slider {
    width: 400px;
  }
}