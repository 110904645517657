.team_sec {
    padding: 80px 0;
}

.portfolio-container-for-team {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

.portfolio-container-list {
    display: flex;
    gap: 2rem;
    flex-wrap: wrap;
    justify-content: center;
    width: initial !important;
    /* max-width: 1200px; */
    margin: 0 auto;
    padding: 1.2rem 0 0 0;
}

.ourteam_card {
    display: flex !important;
    flex-wrap: inherit !important;
}

.card-sample-one-top-container {
    overflow: hidden;
}

.card-sample-one-top-container>img:hover {
    transition: all 2s;
    transform: scale(1.2);
}

.w-100 {
    width: 100% !important;
}

.team_single_box {
    border: 1px solid #dfdfdf;
    border-radius: 10px;
    margin-bottom: 2px;
    transition: all .3s;
    overflow: hidden;
    width: 100%;
    max-width: 25%;
}

.team_name p,
.team_name span {
    font-size: 18px;
    margin: 0;
}

@media (min-width: 1200px) {
    .portfolio-container-for-team {
        width: 100%;
        max-width: 1140px;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .portfolio-container-for-team {
        max-width: 960px;
    }

    .team_name p,
    .team_name span {
        font-size: 18px;
        margin: 0;
    }

    .team_sec {
        padding: 50px 0;
    }

    .ourvaluable_client_sec {
        padding: 50px 0;
    }
}

@media only screen and (max-width: 768px) {
    .portfolio-container-for-team {
        max-width: 720px;
    }

    .team_sec {
        padding: 40px 0;
    }

    .ourteam_card {
        gap: 2rem !important;
    }
}

@media (max-width: 528px) {
    .portfolio-container-for-team {
        max-width: fit-content;
    }

    .team_sec {
        padding: 28px 0;
    }

    .portfolio-container-list {
        flex-wrap: wrap !important;
    }

    .team_single_box {
        max-width: 43%;
    }

    .ourteam_card {
        gap: 1rem !important;
    }

    .team_name p,
    .team_name span {
        font-size: 14px;
    }
}