.input-sample-one {
  padding: 1rem;
  border-radius: 0.4rem;
  border: 1px solid white;
  outline: none;
  background-color: transparent;
  color: white;
  font-size: 1.2rem;
  margin: 0 14px 19px 0;
  width: 40%;
}

@media (min-width: 921px) and (max-width: 1338px) {
  .input-sample-one {
    width: 10rem;
  }
}

@media only screen and (max-width: 664px) {
  .input-sample-one {
    width: 80%;
  }
}

@media (max-width: 528px) {
  .input-sample-one {
    width: 100%;
    margin: 0;
    margin-bottom: 20px;
  }
}