.breadcrumb-section {
  /* padding: 15px 1px; */
  padding: 1rem;
  display: flex;
  gap: 1rem;
  padding: 1% 20%;
  margin-left: 15px;
}

.breadcrumb-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.breadcrumb-section div:last-child {
  color: #005761;
  font-weight: 700;
}

.breadcrumb-section div:last-child span {
  display: none;
}

.breadcrumb-title {
  margin: 0;
}

.breadcrumb-title:first-child {
  color: #000000 !important;
}

@media (min-width: 1200px) {
  .breadcrumb-container {
    width: 100%;
    max-width: 1140px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-container {
    max-width: 960px;
  }

  .breadcrumb-section {
    padding: 10px 0;
  }
}

@media only screen and (max-width: 768px) {
  .breadcrumb-container {
    max-width: 720px;
  }

  .breadcrumb-section {
    padding: 10px 0 0 0;
  }
}

@media (max-width: 528px) {
  .breadcrumb-container {
    max-width: fit-content;
  }

  .breadcrumb-section {
    padding: 10px 0 0 0;
  }
}