footer {
  padding: 30px 0 0 0;
  width: 100%;
  background-color: #060828;
}

.footer-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.footer-main-component {
  display: flex;
  justify-content: center;
  align-items: stretch;
  color: white;
  gap: 2rem;
  padding-bottom: 30px;
}

.footer-main-component-right-section h2,
.footer-main-component-form h2 {
  margin: 0;
}

select,
textarea {
  padding: 1rem;
  border-radius: 0.4rem;
  border: 1px solid white;
  outline: none;
  background-color: transparent;
  color: white;
  font-size: 1.2rem;
  margin: 1rem;
}

/* select {
  width: 17.5rem;
} */

textarea {
  width: 35rem;
}

.footer-company-address-list {
  display: grid;
  grid-template-columns: auto auto;
  grid-row: auto auto;
  grid-gap: 1rem;
  padding-top: 20px;
}

.footer-company-address {
  width: 100%;
  padding: 1rem 1rem 1rem 0;
  display: flex;
  gap: 1rem;
}

select {
  margin: 0 14px 19px 0 !important;
  width: 46%;
}

.footer-container-copywriter-container {
  background-color: #005761;
  color: white;
  font-weight: 700;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  padding: 1rem 0;
  text-align: center;
}

.footer-main-component-right-section {
  width: 50%;
}

.contact_form {
  padding: 24px 0;
}

.contact_form_grid {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  justify-content: center;
}

.footer_contact {
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: flex-start;
}

.footer_contact_info {
  display: flex;
  align-items: center;
  margin-bottom: 9px;
}

.footer_contact_info:last-child {
  margin-bottom: 0;
}

.footer_contact_info img {
  margin-right: 10px;
}

.contact_form .button-sample-one {
  margin: 0;
}

.footer-main-component-right-section h3 {
  margin: 0;
}

.footer-main-component-right-section .button-sample-two {
  margin: 8px 1rem 0 0;
}

.footer-company-address-country-name>h6 {
  margin: 0;
  font-size: 18px;
  color: #00b3cb;
  text-align: left;
  font-weight: 500;
  line-height: 0;
  text-transform: uppercase;
}

.footer-company-address-country-name>p {}

@media (min-width: 921px) and (max-width: 1338px) {
  .footer-main-component {
    padding: 5rem 3rem;
    gap: 1rem;
  }

  select {
    width: 12rem;
  }

  textarea {
    width: 25rem;
  }

  .footer-company-address {
    width: 13rem;
    padding: 0rem;
  }

  .footer-company-address img {
    width: 25px;
    height: 20px;
  }

  .footer-company-address-list {
    grid-column-gap: 1.25rem;
    grid-row-gap: 1.25rem;
  }
}

@media (min-width: 665px) and (max-width: 920px) {
  .footer-main-component {
    flex-direction: column;
    text-align: center;
  }
}

@media only screen and (max-width: 664px) {
  .footer-main-component {
    flex-direction: column;
    text-align: center;
  }

  hr {
    display: block;
  }

  .footer-main-component-form {
    text-align: center;
  }

  .footer-company-address-list {
    grid-template-columns: auto;
    grid-row: auto;
    align-items: center;
    justify-content: center;
    grid-row-gap: 2rem;
    margin-bottom: 2rem;
  }

  .footer-company-address {
    border-top: 1px solid white;
  }
}

@media (min-width: 1200px) {
  .footer-container {
    width: 100%;
    max-width: 1140px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .footer-container {
    max-width: 960px;
  }

  footer {
    padding: 50px 0 0 0;
  }

  .footer-main-component {
    padding: 0;
  }

  .footer-main-component-right-section {
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 768px) {
  .footer-container-copywriter-container {
    font-size: 1rem;
  }

  .contact_form .button-sample-one {
    margin: 0 auto;
  }

  .footer-container {
    max-width: 720px;
  }

  footer {
    padding: 50px 0 0 0;
  }

  .footer-main-component {
    padding: 0;
  }

  textarea {
    width: 41.1rem;
  }

  .footer-main-component-right-section {
    width: 100%;
    padding-bottom: 30px;
  }

  .footer_contact {
    align-items: center;
  }

  .footer-company-address-list {
    grid-row-gap: 0;
  }

  .footer-company-address {
    text-align: left;
  }
}

@media (max-width: 528px) {
  .footer-container-copywriter-container {
    font-size: 0.9rem;
  }

  .footer-container {
    max-width: fit-content;
  }

  footer {
    padding: 28px 0 0 0;
  }

  .contact_form_grid {
    margin: 0;
  }

  textarea {
    width: 100%;
  }

  select {
    margin: 0 0 19px 0 !important;
    width: 100%;
  }

  textarea {
    margin: 0;
    margin-bottom: 20px;
  }

  .footer-company-address {
    text-align: left;
  }

  .footer-company-address {
    border: none;
  }
}

@media (min-width: 320px) and (max-width: 420px) {
  .footer-container-copywriter-container {
    font-size: 0.8rem;
  }
}