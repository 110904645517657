.ourjourney_sec {
  padding: 80px 0;
  width: 100%;
}

.our-journey-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.our-journey-container h3 {
  letter-spacing: 0.1rem;
  line-height: 2rem;
}

.our-journey-timeline {
  display: grid;
  gap: 1rem;
  place-items: center;
}

.our-journey-animation {
  opacity: 0;
}

.active3 {
  opacity: 1;
  animation: rotate3 1.5s;
}

@keyframes rotate3 {
  0% {
    transform: rotateY(80deg);
  }

  100% {
    transform: rotateY(0deg);
    opacity: 1;
  }
}

.text-center {
  text-align: center;
}

.ourjiurney_inxlogo {
  display: block;
  margin: 0 auto;
}

@media only screen and (max-width: 620px) {

  .our-journey-container h3 {
    letter-spacing: 0.2rem;
    line-height: 4rem;
  }

  .our-journey-container img {
    width: 200;
    height: 38;
  }

  .our-journey-timeline {
    display: grid;
    gap: 1rem;
    place-items: center;
  }
}


@media (min-width: 1200px) {
  .our-journey-container {
    width: 100%;
    max-width: 1140px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .our-journey-container {
    max-width: 960px;
  }

  .ourjourney_sec {
    padding: 50px 0;
  }
}

@media only screen and (max-width: 768px) {
  .our-journey-container {
    max-width: 720px;
  }

  .ourjourney_sec {
    padding: 40px 0;
  }

  .timeline-container h2 {
    line-height: inherit;
    margin: 0;
  }
}

@media (max-width: 528px) {
  .our-journey-container {
    max-width: fit-content;
  }

  .ourjourney_sec {
    padding: 28px 0;
  }

  .our-journey-container h3 {
    letter-spacing: 1px;
    line-height: 1.5rem;
  }

  .timeline-container h2 {
    line-height: 2rem;
    font-size: 22px;
    letter-spacing: 0px;
  }
}