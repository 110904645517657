.home-banner-sec {
  padding: 20px 0;
  background-color: #444;
}

.home-banner-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.company-intro-main-container {
  display: flex;
  justify-content: space-around;
}

.company-intro-main-container-content-data {
  text-align: left;
  font-size: 1.5rem;
  width: 50%;
}

.company-intro-main-container-content-data h1 {
  color: #ff5c81;
}

.goMobileGoCloud {
  color: #ffc400;
}

.descriptionText {
  color: #fff;
  max-width: 450px;
}

.imgDiv {
  width: 50%;
  text-align: center;
}

.imgDiv img {
  width: initial;
  max-width: initial;
  max-height: 600px;
  /* object-fit: contain; */
}

.company-intro-main-container-content-data .button-sample-one {
  margin: 0 auto 0 0;
}

@media only screen and (max-width: 1440px) {
  .imgDiv img {
    width: 100%;
    max-width: 600px;
    height: 400px;
  }
}

@media only screen and (max-width: 1024px) {
  .imgDiv img {
    width: 100%;
    max-width: 400px;
    height: 400px;
  }

  .company-intro-main-container-content-data h1 {
    margin: 10px 0;
  }

  .descriptionText {
    margin: 10px 0;
  }
}

@media (min-width: 1200px) {
  .home-banner-container {
    width: 100%;
    max-width: 1140px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .home-banner-container {
    max-width: 960px;
  }
}

@media only screen and (max-width: 768px) {
  .home-banner-container {
    max-width: 720px;
  }

  .imgDiv img {
    max-width: 300px;
    height: 300px;
  }

  .company-intro-main-container-content-data h1 {
    font-size: 27px;
  }

  .goMobileGoCloud {
    font-size: 22px;
  }

  .descriptionText {
    font-size: 18px;
  }

  .mobile-oreder-1 {
    order: 1;
  }

  .mobile-oreder-2 {
    order: 2;
  }
}

@media (max-width: 528px) {
  .home-banner-container {
    max-width: fit-content;
  }

  .company-intro-main-container-content-data {
    width: 100%;
    text-align: center;
  }

  .imgDiv {
    display: none;
  }

  .company-intro-main-container-content-data .button-sample-one {
    margin: 0 auto;
  }
}

@media (min-width: 421px) and (max-width: 480px) {}

@media (min-width: 320px) and (max-width: 420px) {}