.about_section {
  padding: 80px 0;
  background: #f6f6f6;
}

.company-intro2-main-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}


.inx-solutions {
  display: flex;
  flex-wrap: wrap;
}

.company-intro2-main-container-content-data {
  width: 50%;
}

.company-intro2-main-container h3 {
  font-size: 22px;
  color: #060828;
  width: 100%;
  float: left;
  margin-bottom: 22px;
}

.company-intro2-main-container p {
  font-size: 18px;
  color: #060828;
  font-weight: 400;
  line-height: 30px;
  float: left;
  margin-bottom: 0px;
}

.company-intro-hexagons {
  width: 50%;
}

.one {
  width: 32%;
  height: 185px;
  float: left;
  background-image: url("../../Assets/Images/pink.png");
  background-repeat: no-repeat;
  margin: 60px 30px 0 0;
  background-size: 170px;
  position: relative;
}

.one-text {
  position: absolute;
  text-align: center;
  top: 50px;
  left: 30px;
}

.one-text h2 {
  font-size: 36px;
  color: #db7bd1;
  font-weight: 600;
  text-align: center;
  margin: 0;
}

.one-text h4 {
  font-size: 16px;
  text-align: center;
  color: #060828;
  margin: 0;
  font-weight: 600;
}

.two {
  width: 28%;
  height: 160px;
  float: left;
  background-image: url("../../Assets/Images/purple.png");
  background-repeat: no-repeat;
  background-size: 150px;
  margin: 0 30px 0 0;
  position: relative;
}

.two-text {
  position: absolute;
  text-align: center;
  top: 33px;
  left: 38px;
}

.two-text h2 {
  font-size: 30px;
  color: #7e7ec9;
  font-weight: 600;
  text-align: center;
  margin: 0;
}

.two-text h4 {
  font-size: 14px;
  text-align: center;
  color: #060828;
  margin: 0;
  font-weight: 600;
}

.three {
  width: 28%;
  height: 160px;
  float: left;
  background-image: url("../../Assets/Images/green.png");
  background-repeat: no-repeat;
  background-size: 150px;
  margin: 110px 0 0;
  position: relative;
}

.three-text {
  position: absolute;
  text-align: center;
  top: 44px;
  left: 29px;
}

.three-text h2 {
  font-size: 30px;
  color: #2ae130;
  font-weight: 600;
  text-align: center;
  margin: 0;
}

.three-text h4 {
  font-size: 14px;
  text-align: center;
  color: #060828;
  margin: 0;
  font-weight: 600;
}

.four {
  width: 100%;
  height: 270px;
  float: left;
  background-image: url("../../Assets/Images/blue.png");
  background-repeat: no-repeat;
  margin: -80px 0 0;
  background-position: 130px;
  position: relative;
}

.four-text {
  position: absolute;
  text-align: center;
  top: 75px;
  left: 167px;
}

.four-text h2 {
  font-size: 48px;
  color: #00c5de;
  font-weight: 600;
  text-align: center;
  margin: 0;
}

.four-text h4 {
  font-size: 24px;
  text-align: center;
  color: #060828;
  margin: 0;
  font-weight: 600;
}

.five {
  width: 56%;
  height: 205px;
  float: left;
  background-image: url("../../Assets/Images/yellow.png");
  background-repeat: no-repeat;
  margin: -40px 0 0 20px;
  position: relative;
}

.five-text {
  position: absolute;
  text-align: center;
  top: 75px;
  left: 53px;
}

.five-text h2 {
  font-size: 36px;
  color: #dea700;
  font-weight: 600;
  text-align: center;
  margin: 0;
}

.five-text h4 {
  font-size: 16px;
  text-align: center;
  color: #060828;
  margin: 0;
  font-weight: 600;
}

.six {
  width: 36%;
  height: 205px;
  float: right;
  background-image: url("../../Assets/Images/red.png");
  background-repeat: no-repeat;
  margin: -40px 20px 0 0;
  position: relative;
}

.six-text {
  position: absolute;
  text-align: center;
  top: 65px;
  left: 59px;
}

.six-text h2 {
  font-size: 36px;
  color: #e24966;
  font-weight: 600;
  text-align: center;
  margin: 0;
}

.six-text h4 {
  font-size: 16px;
  text-align: center;
  color: #060828;
  margin: 0;
  font-weight: 600;
}

@media (min-width: 1200px) {
  .company-intro2-main-container {
    width: 100%;
    max-width: 1140px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .company-intro2-main-container {
    max-width: 960px;
  }

  .about_section {
    padding: 50px 0;
  }
}

@media only screen and (max-width: 1024px) {
  .inx-solutions {
    flex-direction: column;
  }

  .company-intro2-main-container-content-data {
    width: 100%;
  }

  .line {
    line-height: 0;
    font-size: 36px;
    margin: 0;
  }

  .inx-solutions {
    flex-direction: column;
  }

  .company-intro2-main-container h3 {
    margin-bottom: 16px;
  }

  .company-intro2-main-container p {
    margin-top: 0;
  }

  .company-intro-hexagons {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
  }
}

@media (max-width: 991.98px) {
  .tabletmobile-mb-20 {
    margin-bottom: 20px !important;
  }
}

@media only screen and (max-width: 768px) {
  .company-intro2-main-container {
    max-width: 720px;
  }

  .about_section {
    padding: 40px 0;
  }

  .company-intro2-main-container p {
    font-size: 16px;
  }

  .company-intro2-main-container h3 {
    margin-bottom: 8px;
    margin-top: 6px;
  }
}

@media (max-width: 528px) {
  .company-intro2-main-container {
    max-width: fit-content;
  }

  .about_section {
    padding: 28px 0;
  }

  .company-intro2-main-container h3 {
    font-size: 18px;
  }

  .one {
    width: 33.33%;
    margin: 60px 0 0;
    background-size: 110px;
  }

  .one-text {
    top: 33px;
    left: 20px;
  }

  .one-text h2,
  .two-text h2,
  .three-text h2,
  .four-text h2,
  .five-text h2,
  .six-text h2 {
    font-size: 16px;
  }

  .one-text h4,
  .two-text h4,
  .three-text h4,
  .four-text h4,
  .five-text h4,
  .six-text h4 {
    font-size: 12px;
  }

  .two {
    width: 33.33%;
    margin: 0;
    background-size: 110px;
  }

  .two-text {
    position: absolute;
    text-align: center;
    top: 24px;
    left: 23px;
  }

  .three {
    width: 33.33%;
    margin: 60px 0 0;
    background-size: 110px;
  }

  .four {
    width: 50%;
    height: 150px;
    margin: -120px auto 0 90px;
    background-size: 130px;
    background-position: 50%;
  }

  .five {
    width: 33.33%;
    height: 120px;
    background-size: 110px;
  }

  .six {
    width: 33.33%;
    height: 120px;
    background-size: 110px;
  }

  .three-text {
    top: 32px;
    left: 16px;
  }

  .four-text {
    top: 47px;
    left: 44px;
  }

  .five-text {
    top: 45px;
    left: 34px;
  }

  .six-text {
    top: 35px;
    left: 30px;
  }
}

@media (min-width: 421px) and (max-width: 480px) {
  .four-text {
    left: 57px;
  }
}