.partners-container-our-testimonials {
  padding: 2.6rem 0;
  display: grid;
  place-items: center;
  width: 100%;
  background-image: url(../../Assets/Images/testimonial_bg.jpg);
}

.main_title-our-testimonials {
  font-size: 50px;
  font-weight: 700;
  color: #ffffff;
  width: 100%;
  float: left;
  line-height: 36px;
}

.sub-heading2 {
  width: 100%;
  text-align: center;
  letter-spacing: 2px;
  line-height: 2rem;
  font-weight: 500;
  font-size: 1.2rem;
  color: #ffffff;
}

.testimonial_quotes {
  /* margin-top: -60px; */
  padding-bottom: 5px;
}

.our-testimonial-content {
  color: #fff;
}

.line {
  width: auto;
  text-align: center;
  line-height: 55px;
  font-size: 48px;
  font-weight: 600;
}

.main_title-our-testimonials span {
  color: #005761;
}

.pt-5,
.py-5 {
  padding-top: 3rem;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem;
}

.float-left {
  float: left;
}

.our-testimonial-cards-arrow {
  width: 50px;
  height: 50px;
  background: white;
  border-radius: 50%;
  margin: auto 0;
  display: grid;
  place-items: center;
}

.our-testimonial-cards-inner,
.our-testimonial-cards {
  display: flex;
}

@media only screen and (max-width: 1100px) {
  .our-testimonial-cards {
    transform: scale(0.8);
  }
}

@media (min-width: 420px) and (max-width: 869px) {
  /* .our-testimonial-cards {
    transform: scale(0.8);
  } */

}

@media (max-width: 528px) {
  .our-testimonial-cards {
    margin-left: -9px;
  }
}


@media (min-width: 320px) and (max-width: 420px) {
  .our-testimonial-cards {
    margin-left: -34px;
  }

  .sub-heading-testimonial {
    width: 79%;
    margin: 0 auto;
    margin-top: 30px;
    line-height: 26px;
  }
}