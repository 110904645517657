.button-sample-one {
  border: none;
  outline: none;
  border-radius: 2rem;
  padding: 0.6rem 2rem;
  background-color: #005761;
  color: white;
  font-weight: 700;
  min-width: 3rem;
  font-size: 1.2rem;
  display: block;
  transition: 0.5s;
}

.button-sample-one:hover {
  background-color: #000;
}

.button-sample-two {
  border: none;
  cursor: pointer;
  margin: 2rem 2rem 0 0;
  outline: none;
  border-radius: 50%;
  padding: 0.6rem 0.8rem;
  background-color: #005761;
  color: white;
  transition: all 0.2s;
}

.button-sample-two:hover {
  background-color: #000;
  /* transform: translateY(-15px); */
  transform: translate(0, -10px);
  border: 1px solid rgb(217, 217, 217);
}

.button-sample-three {
  width: 200px;
  height: 60px;
  box-shadow: 0 0 5px 0.5px #e7e7e7;
  border-radius: 30px;
  border: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 3px auto;
}

.linear-wipe {
  text-align: center;
  background: linear-gradient(90deg, #060828 10%, #fff 50%, #005761 60%);
  background-size: 200% auto;
  color: #060828;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 1s linear infinite;
  font-size: 20px;
  font-weight: 700;
  padding: 0;
  margin: 0;
}

@keyframes shine {
  100% {
    background-position: 200%;
  }
}

@media only screen and (max-width: 768px) {
  .button-sample-one {
    font-size: 1rem;
  }
}
