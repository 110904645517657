.ourservice_sec {
  padding: 80px 0;
}

.ourservices-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.our-services-container-list {
  display: flex;
  justify-content: space-between;
  padding: 2rem 30px;
  gap: 2rem;
}

.ourservices-container .button-sample-one {
  margin: 0 auto;
}




@media (min-width: 1200px) {
  .ourservices-container {
    width: 100%;
    max-width: 1140px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .ourservices-container {
    max-width: 960px;
  }

  .ourservice_sec {
    padding: 50px 0;
  }
}


@media only screen and (max-width: 768px) {
  .ourservices-container {
    max-width: 720px;
  }

  .ourservice_sec {
    padding: 40px 0;
  }

  .our-services-container-list {
    flex-wrap: wrap;
  }

  .ourservices-container>.button-sample-one {
    margin: 0 auto;
  }
}

@media (max-width: 528px) {
  .ourservices-container {
    max-width: fit-content;
  }

  .ourservice_sec {
    padding: 28px 0;
  }

  .ourservice-card {
    width: 100% !important;
    margin-bottom: 0px;
  }

  .card-sample-four-container {
    height: 14rem !important;
  }

  .our-services-container-list {
    padding: 2rem 10px;
  }
}