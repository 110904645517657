.whoweare_sec {
  padding: 80px 0;
  background-color: #f6f6f6;
  width: 100%;
}

.who-we-are-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.who-we-are-description {
  opacity: 0;
}

.active2 {
  opacity: 1;
  animation: rotate 1.5s;
}

.mb-0 {
  margin-bottom: 0 !important;
}

@keyframes rotate {
  0% {
    transform: rotateY(80deg);
    /* opacity: 0; */
  }

  100% {
    transform: rotateY(0deg);
    opacity: 1;
  }
}

@media (min-width: 1200px) {
  .who-we-are-container {
    width: 100%;
    max-width: 1140px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .who-we-are-container {
    max-width: 960px;
  }

  .whoweare_sec {
    padding: 50px 0;
  }


}

@media only screen and (max-width: 768px) {
  .about-us-section {
    height: 300px;
  }

  .who-we-are-container {
    max-width: 720px;
  }

  .whoweare_sec {
    padding: 40px 0;
  }

}


@media (max-width: 528px) {
  .who-we-are-container {
    max-width: fit-content;
  }

  .whoweare_sec {
    padding: 28px 0;
  }
}