.about-us-section {
  background-image: url("../../../Assets/Images/about_banner.jpg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 380px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: white;
}

.main_title2 .line::after {
  content: "";
  display: block;
  width: 100px;
  height: 2px;
  background: #e6e6e6;
  margin: 30px 0 20px calc(50% - 50px);
}

.about-us-section h1 {
  padding: 0;
  margin: 0;
}

.about-us-section h2 {
  padding: 0;
  margin: 0;
  letter-spacing: 0.1rem;
}

@media (max-width: 528px) {
  .about-us-section {
    height: 200px !important;
  }

  .banner-title {
    font-size: 18px;
  }

  .main_title2 .line::after {
    content: "";
    display: block;
    width: 100px;
    height: 2px;
    background: #e6e6e6;
    margin: 20px 0 10px calc(50% - 50px);
  }

  .main_title2 .line {
    font-size: 22px !important;
    line-height: 0px !important;
  }
}

@media (min-width: 320px) and (max-width: 420px) {
  .banner-title {
    font-size: 15px;
  }
}