.card-sample-one-top-container {
  width: 100%;
  position: relative;
  /* border-top-left-radius: "2rem";
  border-top-right-radius: "2rem"; */
}

.card-sample-one-top-container img {
  object-fit: cover;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
}

.card-sample-one-top-container h3 {
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%;
  background-color: rgb(24, 24, 24);
  display: none;
  top: -1.2rem;
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
}

.card-sample-one-bottom-container {
  font-weight: 600;
  font-size: 0.9rem;
  letter-spacing: 2px;
  text-align: center;
  padding: 15px 19px 19px 20px;
}

.team_name {
  min-height: 5.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: 600;
  flex-direction: column;
  border-top: 1px solid #dfdfdf;
}

.team_single_box img {
  transition: all 2s;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

  width: 100%;
  max-width: 313px;
  height: 100%;
}

.team_name span {
  width: 100%;
  float: left;
  text-align: center;
  color: #005761;
  font-size: 1rem;
  font-weight: 400;
}

.card-sample-one-top-container:hover h3 {
  display: grid;
  place-items: center;
  opacity: 0.82;
  color: white;
}

.card-sample-two-container {
  /* width: 16rem;
  height: 6.25rem; */
  background-color: white;
  border: 1px solid rgb(235, 235, 235);
  padding: 16px 46px;
  /* display: grid;
  place-items: center;
  float: left; */
}

.card-sample-two-container img {
  object-fit: contain;
}

.card-sample-two-container:hover {
  box-shadow: 0 3px 10px 0px rgb(226, 226, 226);
  border: none;
}

.card-sample-two-container::before {
  box-shadow: 0 3px 10px 0px rgb(226, 226, 226);
}

card-sample-two-container::before {
  box-shadow: 0 3px 10px 0px rgb(226, 226, 226);
}

.card-design-for-testimonial {
  width: "354px";
  display: "flex";
  text-align: "left";
}

.testimonials_section-for-testimonial-card {
  color: #060828;
  position: relative;
  width: 15rem;
  padding: 2rem;
  background: #fff;
  border-radius: 10px;
  margin-bottom: 2rem;
  font-size: 18px;
  line-height: 30px;
  height: 20rem;
  text-align: center;
  margin-left: 20px;
}

.testimonial_quotes-for-card {
  /* / margin-top: -60px; / */
  padding-bottom: 5px;
}

.testimonials_section-for-testimonial-name {
  position: relative;
  padding: 0 0 0 15px;
  align-items: center;
  color: #ffffff;
  font-size: 24px;
  font-weight: bold;
}

.testimonials_section-for-testimonial-designation {
  position: relative;
  padding: 0 0 0 15px;
  align-items: center;
  color: #ffffff;
  font-size: 18px;
}

.card-sample-four-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 17rem;
  border: 1px solid black;
  border-radius: 2rem;
  padding: 1rem;
}

.card-sample-four-image {
  height: 7rem;
  width: 6.5rem;
}

.card-sample-four-container-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.ourportfolio_card {
  position: relative;
  background-color: #ffffff;
  border-radius: 2rem;
  box-shadow: 0 3px 10px 0px rgb(226, 226, 226);
}

.ourservice-card {
  width: 30%;
}

@media only screen and (max-width: 768px) {
  .ourservice-card {
    width: 47%;
  }

  .team_name p,
  .team_name span {
    margin: 0;
    font-size: 17px;
  }

  .team_name {
    min-height: 4rem;
  }
}

@media (max-width: 528px) {
  .button-sample-one {
    font-size: 14px;
  }

  .testimonials_section-for-testimonial-card {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 380px) {
  .ourportfolio_card {
    border-radius: 1rem;
  }

  .card-sample-one-top-container img {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .card-sample-one-top-container h3 {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  .card-sample-one-bottom-container {
    padding: 15px;
  }
}