.ourpartners_sec {
  padding: 80px 0 0 0;
}

.partners-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}



.partners-container-list {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 2rem;
  justify-content: center;
}

.partners-transform-container {
  background-color: #f6f6f6;
}

.partners-transform-field {
  display: flex;
  gap: 2rem;
  align-items: center;
  width: 100%;
  max-width: 1140px;
  padding: 50px 0;
  margin: 0 auto;
  /* padding: 50px 18%; */
}

.partners-second-container {
  max-width: 66.66%;
}

.partners-second-container.expertbtn {
  max-width: 33.33%;
}

.partners-second-container>h1 {
  font-size: 30px;
  font-weight: 700;
  color: #060828;
  line-height: 40px;
}

.fadeinup_animation {
  -webkit-animation: fadeInUp 1s ease-in both;
  animation: fadeInUp 1s ease-in both;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.partners-transform-field-btn {
  min-width: 100px;
  height: 50px;
  padding: 0 30px;
  border-radius: 25px;
  background: #005761;
  border: 1px solid #005761;
  outline: 0;
  font-size: 18px;
  color: #fff;
  font-weight: 700;
  transition: 0.5s;
}

@media (min-width: 1200px) {
  .partners-container {
    width: 100%;
    max-width: 1140px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .partners-container {
    max-width: 960px;
  }

  .partners-transform-field {
    max-width: 960px;
  }

  .ourpartners_sec {
    padding: 50px 0 0 0;
  }

  .partners-transform-field {
    padding: 20px 0;
  }

  .partners-second-container>h1 {
    font-size: 25px;
  }

  .partners-second-container {
    max-width: 63.66%;
  }
}

@media only screen and (max-width: 768px) {
  .partners-container {
    max-width: 720px;
  }

  .partners-transform-field {
    max-width: 720px;
  }

  .ourpartners_sec {
    padding: 40px 0 0 0;
  }

  .partners-container-list {
    display: flex;
    gap: 0;
    flex-wrap: wrap;
    padding: 0;
    justify-content: center;
  }

  .partners-card {
    max-width: 31%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 20px;
  }

  .partners-card>.card-sample-two-container {
    padding: 0;
  }

  .partners-card>.card-sample-two-container img {
    object-fit: none;
    text-align: center;
    display: block;
    width: 100%;
    min-height: 86px;
  }

  .partners-transform-field {
    flex-direction: column;
  }

  .partners-second-container {
    max-width: 100%;
  }

  .partners-second-container>h1 {
    font-size: 25px;
    font-weight: 500;
    color: #060828;
    line-height: 40px;
    text-align: center;
    margin: 0;
  }

  .partners-second-container.expertbtn {
    max-width: 100%;
  }
}

@media (max-width: 528px) {
  .partners-container {
    max-width: fit-content;
  }

  .partners-transform-field {
    max-width: fit-content;
    padding: 50px 15px;
  }

  .ourpartners_sec {
    padding: 28px 0 0 0;
  }

  .partners-card {
    max-width: 75%;
  }

  .partners-second-container>h1 {
    font-size: 22px;
    line-height: 35px;
  }
}