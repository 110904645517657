.timeline-container {
  display: grid;
  place-items: center;
}

.timeline-components {
  display: flex;
}

.timeline-components-description {
  width: 50%;
  box-sizing: border-box;
  align-items: flex-end;
}

.timeline-components-description h2 {
  letter-spacing: 0.16rem;
  font-weight: 750;
  color: #005761;
  font-size: 2rem;
}

.timeline-left {
  padding: 30px 50px 30px 0;
  text-align: right;
}

.timeline-right {
  padding: 30px 0 30px 50px;
  text-align: left;
}

.timeline-border {
  border-right: 2px dashed #005761;
}

.timeline-image {
  box-sizing: border-box;
  width: 50%;
}

.timeline-container h1 {
  font-size: 36px;
  font-weight: 700;
  color: #005761;
}

.timeline-container h3 {
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  color: #060828;
}

.timeline-container:last-child .timeline-components {
  display: none;
}

.reveal {
  opacity: 0;
}

.reveal.active {
  opacity: 1;
}

.active.fade-bottom {
  animation: rotate 1.5s;
}

.timeline-breaker {
  /* position: relative; */
  text-align: center;
  width: 100%;
}

@keyframes rotate {
  0% {
    transform: rotateY(80deg);
    opacity: 0;
  }

  100% {
    transform: rotateY(0deg);
    opacity: 1;
  }
}

@media only screen and (max-width: 1288px) {
  .timeline-container img {
    width: 20rem;
    height: 16rem;
  }
}

@media only screen and (max-width: 1076px) {
  .timeline-container img {
    width: 17rem;
    height: 13rem;
  }
}

@media only screen and (max-width: 800px) {
  .timeline-components {
    flex-direction: column;
    text-align: center;
  }

  .timeline-container h2 {
    line-height: 4rem;
  }

  .timeline-components-description {
    width: 100%;
  }

  .timeline-border {
    border: none;
  }

  .timeline-image {
    width: 100%;
  }

  .timeline-left {
    padding: 5px;
    text-align: center;
  }

  .timeline-right {
    padding: 5px;
    text-align: center;
  }

  .timeline-breaker-line {
    position: absolute;
    width: 100vw;
    height: 2px;
    display: none;
    top: 34px;
    z-index: -1;
    background-color: green;
  }

  /* .button-breaker { */
  /* position: absolute; */
  /* z-index: 1000; */
  /* } */
}