.portolio_sect {
  background-color: #f6f6f6;
  padding: 80px 0;
}

.portfolio-container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.portfolio-container-list {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  width: 61%;
  padding: 2rem;
}

.portfolio-grid {
  max-width: inherit !important;
  padding: 0 !important;
}

@media (min-width: 1200px) {
  .portfolio-container {
    width: 100%;
    max-width: 1140px;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .portfolio-container {
    max-width: 960px;
  }

  .portolio_sect {
    padding: 50px 0;
  }
}

@media only screen and (max-width: 768px) {
  .portfolio-container {
    max-width: 720px;
  }

  .portolio_sect {
    padding: 40px 0;
  }

  .portfolio-grid {
    margin-right: -15px;
    margin-left: -15px;
  }

  .portfolio-grid-width {
    max-width: 20%;
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-bottom: 20px;
  }

  .portfolio-container-list {
    gap: 0 !important;
  }

  .card-sample-one-top-container img {
    width: 100%;
    /* object-fit: unset; */
    vertical-align: middle;
    height: auto;
  }
}

@media (max-width: 528px) {
  .portfolio-container {
    max-width: fit-content;
  }

  .portolio_sect {
    padding: 28px 0;
  }

  .portfolio-grid-width {
    max-width: 42%;
    padding: 0 10px;
  }

  .portfolio-container {
    width: 100%;
    padding-right: 6px;
    padding-left: 6px;
  }
}


